import React, { Component, Fragment } from "react";
import { graphql } from "gatsby";

import { Link } from "gatsby";
import LayoutGeneral from "../components/layout.general";
import { ArticleList } from "../components/elements/articles/article-list.component";

class BlogListPagination extends Component {
  render() {
    const {
      hasNext,
      hasPrev,
      prevLink,
      nextLink,
      pages,
      language,
    } = this.props.pageContext;

    const nextLabel =
      language?.toLowerCase() === "de" ? "Nächste Seite" : "next page";

    const prevLabel =
      language?.toLowerCase() === "de" ? "Vorherige Seite" : "previous page";

    return (
      <Fragment>
        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
          <div className="-mt-px w-0 flex-1 flex">
            {hasPrev && (
              <Link
                to={prevLink}
                rel="prev"
                className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              >
                <svg
                  className="mr-3 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                {prevLabel}
              </Link>
            )}
          </div>
          <div className="hidden md:-mt-px md:flex">
            {Array.isArray(pages) &&
              pages.length > 1 &&
              pages.map(({ link, isCurrent, page }) => {
                const linkClass = isCurrent
                  ? "border-pink-500 text-pink-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium";
                return (
                  <Link key={link} to={link} className={linkClass}>
                    {page}
                  </Link>
                );
              })}
          </div>
          <div className="-mt-px w-0 flex-1 flex justify-end">
            {hasNext && (
              <Link
                to={nextLink}
                rel="next"
                className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              >
                {nextLabel}
                <svg
                  className="ml-3 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            )}
          </div>
        </nav>
      </Fragment>
    );
  }
}

export default class BlogList extends Component {
  render() {
    const posts = this.props.data.allMdx.edges;
    const context = this.props.pageContext;
    const blogConfig = this.props.data.blogConfig.siteMetadata.blog;
    const { language, pages } = context || { language: "de" };

    const totalPages = pages.length;
    const currentPage = pages.find(({ isCurrent }) => isCurrent === true);

    const localizedBlogConfig = blogConfig?.[language] || blogConfig;

    const navigation =
      language?.toLowerCase() === "de"
        ? this.props.data.navigationDe
        : this.props.data.navigationEn;

    const footer =
      language?.toLowerCase() === "de"
        ? this.props.data.footerDe
        : this.props.data.footerEn;

    const pageAddon =
      currentPage.page > 1
        ? "(" + currentPage.page + "/" + totalPages + ")"
        : "";

    const seo = {
      title: `${localizedBlogConfig?.title} ${pageAddon} by echonovum`,
      description: localizedBlogConfig?.description + pageAddon,
    };

    const enrichedPageContext = {
      seo,
      ...context,
    };

    return (
      <LayoutGeneral
        pageContext={enrichedPageContext}
        navigation={navigation}
        footer={footer}
        path={this.props?.location?.pathname}
        seo={seo}
      >
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3"></div>
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              {localizedBlogConfig?.title && (
                <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                  {localizedBlogConfig.title} {pageAddon}
                </h1>
              )}
              {localizedBlogConfig?.description && (
                <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-700 sm:mt-4">
                  {localizedBlogConfig.description}
                </p>
              )}
            </div>
            <div className="mt-12 max-w-lg mx-auto lg:max-w-none">
              <ArticleList articles={posts} />
            </div>
            <BlogListPagination pageContext={context} />
          </div>
        </div>
      </LayoutGeneral>
    );
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $language: String!) {
    blogConfig: site {
      siteMetadata {
        blog {
          de {
            title
            description
          }
          en {
            title
            description
          }
        }
      }
    }
    navigationDe: fragmentsYaml(key: { eq: "navigation-reduced" }) {
      key
      id
      data
    }
    navigationEn: fragmentsYaml(key: { eq: "navigation-reduced-en" }) {
      key
      id
      data
    }
    footerDe: fragmentsYaml(key: { eq: "footer-navigation" }) {
      key
      id
      data
    }
    footerEn: fragmentsYaml(key: { eq: "footer-navigation-en" }) {
      key
      id
      data
    }
    social: fragmentsYaml(key: { eq: "social-profiles" }) {
      key
      id
      data
    }
    allMdx: allMdx(
      filter: {
        frontmatter: {
          type: { eq: "blog-article" }
          language: { eq: $language }
          draft: { ne: true }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            headline
            description
            topic
            date(formatString: "DD.MM.YYYY")
            lead_image {
              publicURL
              childImageSharp {
                id
                fluid(maxWidth: 1080) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                  srcWebp
                  srcSetWebp
                }
                fixed(width: 1080) {
                  base64
                  aspectRatio
                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
            hero {
              theme
              background_color
              accent_color
              foreground_image {
                publicURL
                childImageSharp {
                  id
                  fluid(maxWidth: 1080) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                    srcWebp
                    srcSetWebp
                  }
                  fixed(width: 1080) {
                    base64
                    aspectRatio
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
